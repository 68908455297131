(function () {
    var scriptCache = window.loadedScripts = {};

    if (!window.jQuery || (window.jQuery && parseInt(window.jQuery.fn.jquery.replace('.', '')) < parseInt('1.5.1'.replace('.', '')))) {
        throw new Error('jQuery.loadScript requires jQuery 1.5.1 or greater.');
    }

    $.loadScript = function (url) {
        if (!scriptCache[url]) {
            scriptCache[url] = $.ajax({
                url: url,
                dataType: "script",
                cache: true
            });

            scriptCache[url].fail(function (defObj, errorType, errorMsg) {
                console.error('error loading script ' +  url, errorType, errorMsg);
            });
        }

        return scriptCache[url];
    };
}());